<template>
  <page-content>
    <edit-field-header
      :title="title"
      @routerBack="$router.back()"
    />

    <edit-field
      :fields="fields"
      :row-data="row_data"
      post-api="/staffmanage/AddStaff"
      finish-router="staff"
      permission="Sta_Create"
    >
      <template #facility="scope">
        <SlotFacilityRoleIDs :company-id="scope.rowData.companyID" :row-data="scope.rowData"/>
      </template>

      <template v-slot:facilityNfcs="scope">
        <slot-inputs
          :is-edit="scope.isEdit"
          :root-item="scope.rootItem"
          :items="[]"
          :show-add-btn="false"
          :show-delete-btn="false"
        />
      </template>

    </edit-field>
  </page-content>
</template>

<script>
import fields, { reloadFieldData } from "@/views/staff/fields";
import EditField from "@/components/EditField";
import EditFieldHeader from "@/components/EditFieldHeader";
import PageContent from "@/components/PageContent";
import SlotFacilityRoleIDs from "@/views/staff/SlotFacilityRoleIDs";
import common from "@/common";
import SlotInputs from '../../components/FieldInputs';


export default {
  name: "Add",
  components: {
    SlotFacilityRoleIDs,
    PageContent,
    EditFieldHeader,
    EditField,
    fields,
    SlotInputs,
  },
  data() {
    return {
      title: common.getMenuName('staff') + ' - ' + common.getI18n('common.add'),
      fields,
      // 如果是checkbox，里面写 field: [], 如ids: []
      row_data: {
        isActive: true,
        companyID: common.getCompanyId()
      }
    }
  },
  created() {
    reloadFieldData()
  },
}
</script>

<style scoped>

</style>
